import React from "react";
import PayWindow from "../components/component/PayWindow/PayWindow.js";
import usePayData from "../hooks/pay/usePayData .ts";


function Pay() {
  const payData = usePayData();

  if (!payData) return null;

  return (
    <>
      <PayWindow payData={payData} />
    </>
  );
}

export default Pay;
