const Offer = () => {
  return (
    <div>
      <h2>Оферта сервиса</h2>
      <p>Администрация проекта может запросить дополнительную информацию для проверки санкционированного доступа к вашей учетной записи.</p>

      <p>Вы не можете расторгнуть сделку на любой ее стадии после успешной передачи предметов в системе Steam.</p>

      <p>Балансы пользователей и цены на скины на нашем сайте хранятся в USD. Если вы выбираете другую валюту (RUB, UAH, KZT), цены будут конвертироваться по соответствующему курсу. Вы можете изменить валюту в верхней части сайта. В зависимости от выбранной валюты, вам будут доступны разные способы вывода средств.</p>

      <p>Средства, полученные за продажу скинов, можно вывести на ваши реквизиты (карта, СПБ, Юмани, криптокошелек).</p>

      <p>Напоминаем, что в некоторых случаях при выводе на кредитные карты платеж может обрабатываться банком до 3 рабочих дней, и это никак не зависит от нашего сервиса.</p>

      <p>Мы не несем ответственности в случае ошибки при заполнении реквизитов, если деньги были переведены на неверные данные. Если транзакция неуспешна, вы всегда можете откорректировать реквизиты с помощью нашей технической поддержки.</p>

      <p>Стандартное время выплаты составляет 5 минут, но обычно перевод осуществляется быстрее. При возникновении проблем с платежным шлюзом выплата может задерживаться; такие заявки будут оплачены сразу же после решения проблемы с платежной системой. В 99,9% случаев ожидание не превышает 24 часов, чаще всего занимает несколько часов. Мы приносим извинения за возможные неудобства, но не можем повлиять на эту ситуацию. Наши консультанты всегда предложат вам другие варианты выплаты, если они доступны.</p>

      <p>Невозможно с уверенностью утверждать, что аккаунтом, авторизованным в нашей системе, управляет его владелец. Если ваш аккаунт был взломан и предметы были проданы на нашем сервисе, мы не несем за это ответственности. Вам следует обратиться в правоохранительные органы с жалобой на реквизиты, на которые была осуществлена выплата.</p>

      <p>Мы не можем помочь вам, если ваш API-ключ был украден и злоумышленники использовали его для подмены оригинального обмена. Подробнее о Steam API Scam вы можете прочитать <a href="https://ru.csgo.com/news/18549-steam-api-scam">здесь</a>.</p>

      <p>Продажи с украденных аккаунтов отслеживаются в меру возможности. При выявлении таких инцидентов выплата заявки может быть отменена. Если у сервиса есть причины подозревать пользователя в продаже предметов с украденного аккаунта, выплата за такие предметы может быть отложена до разблокировки предметов. В случае блокировки нашего аккаунта выплата будет</p>

    </div>
  );
};

export default Offer;
