import "./FAQ.css";

const FAQ = () => {
  return (
    <div className="faq_container">
      <h2>FAQ</h2>
      <div className="faq_container-blocks">
        <div>
          <h1>01</h1>
          <div>
            <h4>Что такое sell-skins.ru?</h4>
            <p>
              Sell-skins.ru — это платформа, где вы можете продать скины из игр, таких как CS2 (CS:GO), RUST, Dota 2 и TF2. Вывод средств осуществляется на указанные вами платежные реквизиты простым и безопасным способом, всего за несколько шагов:
              <br />- выберите скины, которые хотите продать;
              <br />- введите свои платежные данные;
              <br />- примите предложение от нашего бота;
              <br /> - получите моментальную выплату на указанные реквизиты.

            </p>
          </div>
        </div>

        <div>
          <h1>02</h1>
          <div>
            <h4>Как это работает?</h4>
            <p>
              Это очень просто! Мы обмениваем ваши скины на реальные деньги или криптовалюту. Для начала войдите на сайт через свою учетную запись Steam, укажите адрес электронной почты и URL для обмена в Steam. После этого ваш инвентарь будет загружен с предложенными ценами для каждого товара. Как только вы завершите сделку и мы получим ваши скины, деньги будут отправлены вам через выбранный способ оплаты.
            </p>
          </div>
        </div>


        <div>
          <h1>03</h1>
          <div>
            <h4>Как формируется цена на скины?</h4>
            <p>
              Цена рассчитывается на основе стоимости предмета на маркетплейсах, где продают скины за реальные деньги. Мы не ориентируемся на цену Торговой площадки Steam.

            </p>
          </div>
        </div>


        <div>
          <h1>04</h1>
          <div>
            <h4>Почему цена на скины ниже, чем в Steam?</h4>
            <p>
              При продаже скинов на торговой площадке Steam средства зачисляются на внутренний кошелек, и вывести их на карту невозможно. Поэтому реальные деньги ценнее, чем баланс в Steam. Соответственно, на сервисах, где можно вывести деньги на карту или в криптовалюте, стоимость предметов будет ниже. Мы предлагаем около 60% от цены торговой площадки Steam (но мы не ориентируемся на цену Steam, это примерное значение). Если кто-то предлагает выкупить скины по цене торговой площадки, скорее всего, это мошенничество, будьте осторожны!
            </p>
          </div>
        </div>


        <div>
          <h1>05</h1>
          <div>
            <h4>Какая комиссия при продаже?</h4>
            <p>
              Размер комиссии зависит от выбранной системы оплаты. Комиссию и итоговую сумму можно увидеть при оформлении платежа — на нашем сервисе нет никаких скрытых комиссий.
            </p>
          </div>
        </div>

        <div>
          <h1>06</h1>
          <div>
            <h4>Как быстро я получу свои деньги после продажи?</h4>
            <p>
              Все выплаты происходят автоматически. Стандартное время перевода средств составляет 5 минут, но обычно деньги приходят быстрее. Задержки возможны только в случае проблем с платежными системами, но такие ситуации возникают редко.

            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
