import React, { useState } from "react";

const SvgIcon = ({ isHovered }) => {
  const strokeColor = isHovered ? "#6EAEFA" : "#797979";
  const transitionStyle = {
    transition: "0.18s",
  };

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31152 12.0322C8.31152 12.8809 8.96779 13.5634 9.77279 13.5634H11.4178C12.1178 13.5634 12.6865 12.9684 12.6865 12.2247C12.6865 11.4284 12.3365 11.1397 11.8203 10.9559L9.18652 10.0372C8.67027 9.85343 8.32028 9.57344 8.32028 8.76844C8.32028 8.03344 8.88902 7.42969 9.58902 7.42969H11.234C12.039 7.42969 12.6953 8.11219 12.6953 8.96094"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M10.499 6.5625V14.4375"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M19.25 10.5C19.25 15.33 15.33 19.25 10.5 19.25C5.67 19.25 1.75 15.33 1.75 10.5C1.75 5.67 5.67 1.75 10.5 1.75"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M14.875 2.625V6.125H18.375"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M19.25 1.75L14.875 6.125"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
    </svg>
  );
};

export default SvgIcon;
