import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/Logo.svg";
import MoneySend from "../assets/img/money-send.js";
import GoHome from "../assets/img/go-home.js";
import MoneyRecive from "../assets/img/money-recive.js";

import InfoCircle from "../assets/img/info-circle.js";
import ReceiptDiscount from "../assets/img/receipt-discount.js";
import Steam from "../assets/img/steam.svg";
import { BACKEND_URL } from "../assets/constants.js";
import "./Header.css"; // Подключение стилей
import useUser from "../hooks/user/useUser.ts";
import { LoginBlock } from "./LoginBlock";
import usePayData from "../hooks/pay/usePayData .ts";

const Header = () => {
  const [hoveredItem, setHoveredItem] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const user = useUser();
  const payData = usePayData();

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem("");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="logo">
        <img src={Logo} alt="SkinnSeller" />
      </div>

      {payData ? <nav className={menuOpen ? "open" : ""}>
        <div
          className="sell"
          onMouseEnter={() => handleMouseEnter("sell")}
          onMouseLeave={handleMouseLeave}
        >
          <GoHome isHovered={hoveredItem === "sell"} />
          <a href={payData.redirect}>Вернусть на сайт</a>
        </div>
      </nav> : <nav className={menuOpen ? "open" : ""}>
        <div
          className="sell"
          onMouseEnter={() => handleMouseEnter("sell")}
          onMouseLeave={handleMouseLeave}
        >
          <MoneySend isHovered={hoveredItem === "sell"} />
          <Link to="/sell">Продать скины</Link>
        </div>
        <div
          className="buy"
          onMouseEnter={() => handleMouseEnter("buy")}
          onMouseLeave={handleMouseLeave}
        >
          <div className="comingsoon" >Скоро</div> 
          <MoneyRecive isHovered={hoveredItem === "buy"} />
          <Link>Купить скины</Link>
        </div>
        {/* <div
          className="faq-partner"
          onMouseEnter={() => handleMouseEnter("emptyWallet")}
          onMouseLeave={handleMouseLeave}
        >
          <img className="comingsoon" src={ComingSoon}/> 
          <EmptyWalletTime isHovered={hoveredItem === "emptyWallet"} />
          <Link to="/parther">Партнерка</Link>
        </div>
        <div
          className="feedback-bonus"
          onMouseEnter={() => handleMouseEnter("receiptDiscount")}
          onMouseLeave={handleMouseLeave}
        >
          <img className="comingsoon" src={ComingSoon}/> 
          <ReceiptDiscount isHovered={hoveredItem === "receiptDiscount"} />
          <Link to="/bonus">Бонусы</Link>
        </div>
        <div
          className="feedback-bonus"
          onMouseEnter={() => handleMouseEnter("activity")}
          onMouseLeave={handleMouseLeave}
        >
          <img className="comingsoon" src={ComingSoon}/> 
          <Activity isHovered={hoveredItem === "activity"} />
          <Link to="/feedback">Отзывы</Link>
        </div> */}
        <div
          className="faq-partner"
          onMouseEnter={() => handleMouseEnter("infoCircle")}
          onMouseLeave={handleMouseLeave}
        >
          <InfoCircle isHovered={hoveredItem === "infoCircle"} />
          <Link to="/faq">FAQ</Link>
        </div>
      </nav>}


      {!payData && (!user ? (
        <div className="login">
          <img onClick={() => window.open(`${BACKEND_URL}/api/auth/steam`)} src={Steam} />
          <a href={`${BACKEND_URL}/api/auth/steam`}>Войти через Steam</a>
        </div>
      ) : (
        <LoginBlock />
      ))}


      <div
        className={`hamburger-menu ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
