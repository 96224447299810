import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Sell from "./page/Sell";
import Footer from "./components/component/Footer/Footer";
import Profile from "./page/Profile";
import Pay from "./page/Pay";
import Faq from "./page/Faq";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Sell />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/sell/transaction/:transactionId" element={<Sell />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/pay/transaction/:transactionId" element={<Pay />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
