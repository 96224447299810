const Faq = () => {
    return (
        <div>
            <h2>Вопрос - Ответ</h2>
            <br />

            <h3>Что такое sell-skins.ru?</h3>
            <p>Sell-skins.ru — это платформа, где вы можете продать скины из игр, таких как CS2 (CS:GO), RUST, Dota 2 и TF2. Вывод средств осуществляется на указанные вами платежные реквизиты простым и безопасным способом, всего за несколько шагов:
                <br />  - выберите скины, которые хотите продать;
                <br />- введите свои платежные данные;
                <br /> - примите предложение от нашего бота;
                <br />- получите моментальную выплату на указанные реквизиты.</p>

            <h3>Как это работает?</h3>
            <p>Это очень просто! Мы обмениваем ваши скины на реальные деньги или криптовалюту. Для начала войдите на сайт через свою учетную запись Steam, укажите адрес электронной почты и URL для обмена в Steam. После этого ваш инвентарь будет загружен с предложенными ценами для каждого товара. Как только вы завершите сделку и мы получим ваши скины, деньги будут отправлены вам через выбранный способ оплаты.</p>

            <h3>Как формируется цена на скины?</h3>
            <p>Цена рассчитывается на основе стоимости предмета на маркетплейсах, где продают скины за реальные деньги. Мы не ориентируемся на цену Торговой площадки Steam.</p>

            <h3>Почему цена на скины ниже, чем в Steam?</h3>
            <p>При продаже скинов на торговой площадке Steam средства зачисляются на внутренний кошелек, и вывести их на карту невозможно. Поэтому реальные деньги ценнее, чем баланс в Steam. Соответственно, на сервисах, где можно вывести деньги на карту или в криптовалюте, стоимость предметов будет ниже. Мы предлагаем около 60% от цены торговой площадки Steam (но мы не ориентируемся на цену Steam, это примерное значение). Если кто-то предлагает выкупить скины по цене торговой площадки, скорее всего, это мошенничество, будьте осторожны!</p>

            <h3>Можно ли получить выше цену за мои скины?</h3>
            <p>Нет, цены, которые вы видите, не подлежат обсуждению, однако есть несколько исключений:
                <br />   - если это ошибочная цена. Вы можете связаться с нами, если считаете, что столкнулись с ошибкой ценообразования. Например, если ваш нож, который стоит 1000 долларов, отображается по цене 1 доллар. Хотя такая ситуация крайне маловероятна, если она все же произойдет, свяжитесь с нами как можно скорее!
                <br />  - если вы хотите осуществлять оптовые поставки (от 5000$+ в месяц).
                <br />  - если вы хотите стать партнером в рамках модели B2B.
                <br /> Вы можете написать нам в техподдержку.</p>

            <h3>Почему я должен вам доверять?</h3>
            <p>Мы на рынке скупки с 2024 года. Отзывов у нас пока не так много, но мы уже сотрудничаем с крупными сайтами, которые нам доверяют. К сожалению, мы не можем раскрыть их имена из-за соглашения о неразглашении, но если вы посмотрите депозиты скинов на некоторых площадках, то вы сможете увидеть нас 🙃. Мы также являемся владельцами проекта <a href="https://panel-tradelab.com/">TradeLab</a>, который помогает крупным трейдерам продавать свои скины на различных маркетплейсах. Нам доверяют ежемесячно несколько миллионов долларов.</p>

            <h3>Какая комиссия при продаже?</h3>
            <p>Размер комиссии зависит от выбранной системы оплаты. Комиссию и итоговую сумму можно увидеть при оформлении платежа — на нашем сервисе нет никаких скрытых комиссий.</p>

            <h3>Какая минимальная сумма сделки? </h3>
            <p>Размер минимальной сделки зависит от выбранной системы оплаты и валюты. При выборе нужной вам системы оплаты будет указана минимальная сумма сделки. Если минимальная сумма не будет достигнута, вы не сможете начать процесс продажи.</p>

            <h3>Как быстро я получу свои деньги после продажи?</h3>
            <p>Все выплаты происходят автоматически. Стандартное время перевода средств составляет 5 минут, но обычно деньги приходят быстрее. Задержки возможны только в случае проблем с платежными системами, но такие ситуации возникают редко.</p>

            <h3>Деньги не пришли, что делать?</h3>

            <p>Сначала проверьте, правильно ли указаны реквизиты. Затем проверьте статус платежа: если он находится в статусе “оплачено” и ваши реквизиты указаны верно, свяжитесь с нашей технической поддержкой.</p>

        </div>
    );
};

export default Faq;