import "./MainWindowNotLogin.css";
import Coin from "../../../assets/img/coin.svg";
import IconMain from "../../../assets/img/iconMain.svg";
import Information from "../../../assets/img/information.svg";
import Steam from "../../../assets/img/steam.svg";
import Flash from "../../../assets/img/flash.svg";
import MoneyBlue from "../../../assets/img/money-recive-blue.svg";
import WalletBlue from "../../../assets/img/wallet-blue.svg";
import Timer from "../../../assets/img/timer-pause.svg";
import { BACKEND_URL } from "../../../assets/constants";

const MainWindowNotLogin = () => {
  return (
    <div className="main-window main-window-bg">
      <div className="left-column-item games">
        <div>CS 2</div>
        <div>DOTA 2</div>
        <div>TF2</div>
        <div>RUST</div>
      </div>
      <div className="left-column-item title">
        Продавай свои скины в несколько {" "}
        <spna className="title blue">кликов</spna>
      </div>
      <div className="left-column-item desc">
        Продавайте свои скины из CS2, Dota 2, TF2 и RUST быстро, выгодно и за реальные деньги.
      </div>
      <div className="left-column-item">
        <a href={`${BACKEND_URL}/api/auth/steam`} className="sell-but">
          <img src={Coin} />
          <span>Продать скины</span>
        </a>
      </div>
      <div className="icon-main">
        <img src={IconMain} />
        <div className="icon-main_block-right">
          <img src={Information} />

          <div className="icon-main_block-right-block">
            <span className="icon-main_block-right-title">
              Авторизуйтесь
            </span>
            <span className="icon-main_block-right-desc">
              Пожалуйста войдите через Steam
            </span>
          </div>

          <div className="login">
            <img onClick={() => window.open(`${BACKEND_URL}/api/auth/steam`)} src={Steam} />
            <a href={`${BACKEND_URL}/api/auth/steam`}>Войти через Steam</a>
          </div>
        </div>
      </div>

      <div className="centr-column-item">
        <div>
          <div>
            <img src={Flash} />
            <span>Полностью безопасно!</span>
          </div>
          <span className="centr-column-item-desc">
            Все сделки проходят{" "}
            <span className="centr-column-item-desc_gray">
              напрямую через наш сервис,<br /> без посредников.
            </span>
          </span>
        </div>
        <div>
          <div>
            <img src={MoneyBlue} />
            <span>Без скрытых комиссий!</span>
          </div>
          <span className="centr-column-item-desc">
            Вы сразу видите конечную сумму выплаты <br />
            <span className="centr-column-item-desc_gray">
              с учетом всех комиссий.
            </span>
          </span>
        </div>
        <div>
          <div>
            <img src={Timer} />
            <span>Быстрые выплаты!</span>
          </div>
          <span className="centr-column-item-desc">
            Мы выплачиваем деньги за скины в течение
            <br />
            <span className="centr-column-item-desc_gray">
              15 минут и работаем 24/7.
            </span>
          </span>
        </div>
        <div>
          <div>
            <img src={WalletBlue} />
            <span>Выгодные цены!</span>
          </div>
          <span className="centr-column-item-desc">
          Мы предлагаем самые{' '}
            
            <span className="centr-column-item-desc_gray">
            выгодные цены
            </span><br />
            для мгновенной продажи.
          </span>
        </div>
      </div>
    </div>
  );
};

export default MainWindowNotLogin;
