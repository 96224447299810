import { Link } from "react-router-dom";
import Logo from "../../../assets/img/Logo.svg";
import VkLogo from "../../../assets/img/vk.svg";
import TgLogo from "../../../assets/img/tg.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer_container">
      <div className="footer_container-blocks">
        <div>
          <img src={Logo} />
        </div>
        <div>
          <h4>Наши продукты</h4>
          <a href="https://panel-tradelab.com/">TradeLab</a>
        </div>
        <div>
          <h4>О проекте</h4>
          <a target="_blank" href="https://github.com/DaniilMpala/sell-skins_api">API</a>
          {/* <Link>Отзывы</Link> */}
        </div>
        <div>
          <h4>Поддержка</h4>
          <Link to='/faq'>FAQ</Link>
          <Link to='/faq'>Тех.Поддержка</Link>
          <Link to='/faq?section=howItWorks'>Как это работает?</Link>
        </div>
        <div>
          <h4>Соц. сети</h4>
          <div className="social">
            <img src={VkLogo} />
            <img src={TgLogo} />
          </div>
        </div>
      </div>
      <ul>
        <li>
          <Link to='/faq?section=offer'>Оферта сервиса</Link>
        </li>
        <li>
          <Link to='/faq?section=confidentiality'>Политика концедициальности</Link>
        </li>
      </ul>
      <div className="all_rights_reserved">&copy; 2024 Sell-Skins. All Rights Reserved</div>
    </div>
  );
};

export default Footer;
