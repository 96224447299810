import useUser from "../hooks/user/useUser.ts";
import DefaultImageUser from "../assets/img/defaultImageUser.png";

import "./Header.css"; // Подключение стилей
import { Link } from "react-router-dom";
import DropdownMenu from "./component/DropdownMenu/DropdownMenu.js";
import { BACKEND_URL } from "../assets/constants.js";

export const LoginBlock = () => {
  const user = useUser();

  const dropdownItems = [
    <Link className="link" to="/profile">Профиль</Link>,
    <a className="link danger" href={`${BACKEND_URL}/api/auth/logout`}>Выйти</a>
  ];

  return (
    <div className={`login__block`}>
      <img onClick={() => window.open(`/profile`)} src={user?.photo || DefaultImageUser} />
      <div>

        <div>
          {/* <Link className="link" to="/profile">{user?.username}</Link>, */}
          <span onClick={() => window.open(`/profile`)}>{user?.username}</span>
          <DropdownMenu items={dropdownItems} />
        </div>
      </div>
    </div>
  );
};
