import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import useFetchPayment from "../../../hooks/payment_transaction/useFetchPayment.ts";
import classNames from "classnames";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import { useNavigate } from "react-router-dom";

const Payment = ({ selectedItems, userData }) => {
  const [payment, setPayment] = useState([]);

  const navigate = useNavigate();
  const paymentData = useFetchPayment();
  const createTransaction = useWithMethodFetch("/api/transaction");

  const formik = useFormik({
    initialValues: {
      fullName: "",
      walletNumber: "",
      email: userData.email,
      telegramContact: "",
      paymentId: undefined,
      items: selectedItems.items,
    },
    onSubmit: async (values) => {
      const selectedPayment = payment.find((pay) => pay.select);
      if (!selectedPayment) return toast.error("Выберите метод оплаты");

      // Проверка на наличие регулярного выражения
      const regexWallet = selectedPayment?.metaData?.regexWallet
        ? new RegExp(selectedPayment.metaData.regexWallet)
        : null;

        console.log(regexWallet)

      if (!values.paymentId) return toast.error("Выберите метод оплаты");
      if (!values.items.length)
        return toast.error("Выберите хотя бы один скин");
      if (!values.email && !values.telegramContact)
        return toast.error("Заполните почту или телеграмм для связи с вами");
      if (!values.walletNumber)
        return toast.error("Укажите реквизиты для выплаты");
      if (values.telegramContact && values.telegramContact.length < 4 && !values.telegramContact.includes('@'))
        return toast.error('Поле должно быть иметь длину больше 4 символов и "@"');

      // Проверка поля walletNumber на соответствие регулярному выражению
      if (regexWallet && !regexWallet.test(values.walletNumber)) {
        return toast.error("Номер кошелька не соответствует формату");
      }

      console.log("Form values:", values);

      toast.info("Создание транзакции...");

      await createTransaction.request({
        method: "POST",
        body: values,
      });
    },
  });

  useEffect(() => {
    if (!createTransaction?.data) return;

    toast[createTransaction.data.typeResp](createTransaction.data.textResp);

    if (createTransaction.data.typeResp === "success") {
      navigate(`/sell/transaction/${createTransaction.data.transactionId}`);
    }
  }, [createTransaction.data]);

  const handleSelect = (pay) => {
    setPayment(payment.map((v) => ({ ...v, select: v.id === pay.id })));
    formik.setFieldValue("paymentId", pay.id);
  };

  useEffect(() => {
    if (paymentData?.data) setPayment(paymentData.data);
  }, [paymentData.data]);

  useEffect(() => {
    formik.setFieldValue("items", selectedItems.items);
  }, [selectedItems.items]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <span className="main-window-auth__right_block__selected">
          Выбрано <span>{selectedItems.count}</span> предмет(ов)
        </span>
        <h3 className="main-window-auth__right_block__selected-summa">
          {selectedItems.summa} $
        </h3>

        <div className="main-window-auth__right_block__selected-payment">
          {payment.map((pay) => (
            <div
              key={pay.id} // Добавляем ключ для корректного рендера списка
              className={classNames({
                "main-window-auth__right_block__selected-payment-select":
                  pay?.select,
              })}
              onClick={() => handleSelect(pay)}
            >
              <img title={pay.title} src={pay.img} />
            </div>
          ))}
        </div>

        <p className="main-window-auth__right_block__personal-info_desc">
          Заполните данные
        </p>

        <div className="main-window-auth__right_block__personal-info">
          <input
            name="fullName"
            placeholder="ФИО"
            style={{ width: "100%" }}
            onChange={formik.handleChange}
            value={formik.values.fullName}
          />
          <input
            name="walletNumber"
            placeholder="Номер кошелька"
            style={{ width: "100%" }}
            onChange={formik.handleChange}
            value={formik.values.walletNumber}
          />
          <input
            name="email"
            type="email"
            placeholder="Введите email"
            style={{ width: "48%" }}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <input
            name="telegramContact"
            placeholder="@Telegram"
            style={{ width: "48%" }}
            onChange={formik.handleChange}
            value={formik.values.telegramContact}
          />
        </div>

        <button
          className="main-window-auth__right_block__personal-info_submit"
          type="submit"
        >
          Продолжить
        </button>
      </form>
    </>
  );
};

export default Payment;
