import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

// Определяем тип для декодированного токена
interface DecodedToken {
  tradeUrl: string;
  SteamID: string;
  integrationId: number;
  redirect: string;
  typeTransaction: string;
  iat: number;
  exp: number;
}

const usePayData = (): DecodedToken | null => {
  const [payData, setPayData] = useState<DecodedToken | null>(null);

  useEffect(() => {
    const checkToken = () => {
      // Извлечение токена из параметра строки запроса (URL)
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (token) {
        try {
          const decoded: DecodedToken = jwtDecode(token);
          // console.log('Decoded JWT:', decoded);
          setPayData({
            tradeUrl: decoded.tradeUrl,
            integrationId: decoded.integrationId,
            redirect: decoded.redirect,
            SteamID: decoded.SteamID,
            typeTransaction: decoded.typeTransaction,
            iat: decoded.iat,
            exp: decoded.exp,
          });
        } catch (error) {
          console.error('Invalid token:', error);
          setPayData(null);
        }
      } else {
        setPayData(null);
      }
    };

    checkToken();

    const intervalId = setInterval(checkToken, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return payData;
};

export default usePayData;
