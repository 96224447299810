import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import useFetchPayment from "../../../hooks/payment_transaction/useFetchPayment.ts";
import classNames from "classnames";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import { useNavigate, useParams } from "react-router-dom";

const Payment = ({ selectedItems, tradeurl }) => {
  const navigate = useNavigate();
  const createTransaction = useWithMethodFetch("/api/transaction");

  const formik = useFormik({
    initialValues: {
      tradeurl,
      paymentId: -1,
      items: selectedItems.items,
    },
    onSubmit: async (values) => {
      toast.info("Создание транзакции...");

      await createTransaction.request({
        method: "POST",
        body: values,
      });
    },
  });

  useEffect(() => {
    if (!createTransaction?.data) return;

    toast[createTransaction.data.typeResp](createTransaction.data.textResp);

    if (createTransaction.data.typeResp === "success") {
      const token = (new URLSearchParams(window.location.search)).get('token')
      navigate(`/pay/transaction/${createTransaction.data.transactionId}?token=${token}`);
    }
  }, [createTransaction.data]);


  useEffect(() => {
    formik.setFieldValue("items", selectedItems.items);
  }, [selectedItems.items]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <span className="main-window-auth__right_block__selected">
          Выбрано <span>{selectedItems.count}</span> предмет(ов)
        </span>
        <h3 className="main-window-auth__right_block__selected-summa">
          {selectedItems.summa} $
        </h3>


        <button
          className="main-window-auth__right_block__personal-info_submit"
          type="submit"
        >
          Создать депозит
        </button>
      </form>
    </>
  );
};

export default Payment;
