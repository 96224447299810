import React from "react";
import FaqWindow from "../components/component/FaqWindow/FaqWindow";



function Faq() {
  return (
    <>
      <FaqWindow  />
    </>
  );
}

export default Faq;
