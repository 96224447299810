import React from "react";
import useUser from "../hooks/user/useUser.ts";
import MainWindowNotLogin from "../components/component/MainWindowNotLogin/MainWindowNotLogin";
import FeedbackBlock from "../components/component/FeedbackBlock/FeedbackBlock";
import StatsBlock from "../components/component/StatsBlock/StatsBlock.js";
import FAQ from "../components/component/FAQ/FAQ.js";
import AboutMain from "../components/component/AboutMain/AboutMain.js";
import MainWindow from "../components/component/MainWindow/MainWindow.js";

function Sell() {
  const user = useUser();

  return (
    <>
      {!user ? <MainWindowNotLogin /> : <MainWindow user={user}/>}
      <FeedbackBlock />
      <StatsBlock />
      <FAQ />
      {/* <AboutMain /> */}
    </>
  );
}

export default Sell;
