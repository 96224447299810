import React, { useState } from "react";

const GoHome = ({ isHovered }) => {
  const strokeColor = isHovered ? "#6EAEFA" : "#797979";
  const transitionStyle = {
    transition: "0.18s",
  };

  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={transitionStyle} d="M13.1577 0C12.7904 0 12.4908 0.29987 12.4908 0.667536V3.85919L8.96889 0.333768C8.7084 0.0730117 8.27076 0.0730117 8.01027 0.333768L0.195372 8.17731C-0.0651241 8.43807 -0.0651241 8.85528 0.195372 9.11604C0.455869 9.37679 0.872663 9.37679 1.13316 9.11604L8.48958 1.75228L15.8668 9.1369C15.9971 9.26728 16.1742 9.3455 16.3462 9.3455C16.5181 9.3455 16.6744 9.26728 16.8046 9.1369C17.0651 8.87614 17.0651 8.45893 16.8046 8.19817L14.4914 5.88266V0.667536C14.4914 0.29987 14.1918 0 13.8245 0H13.1577ZM8.48958 2.98305L1.154 10.3259V13.9974C1.154 15.103 2.05011 16 3.15461 16H13.8245C14.9291 16 15.8252 15.103 15.8252 13.9974V10.3259L8.48958 2.98305ZM7.15584 9.32464H9.82332C10.1906 9.32464 10.4902 9.62451 10.4902 9.99218V13.9974C10.4902 14.3651 10.1906 14.6649 9.82332 14.6649H7.15584C6.78854 14.6649 6.48897 14.3651 6.48897 13.9974V9.99218C6.48897 9.62451 6.78854 9.32464 7.15584 9.32464Z" fill={strokeColor} />
    </svg>

  );
};

export default GoHome;
